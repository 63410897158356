.services {
  height: 75vh; // fallback options
  height: calc(var(--vh, 1vh) * 75);
  width: 100%;
  background-color: $light-background;
  overflow: hidden;
  @include media("<1000px") {
    height: 100%;
  }

  .container {
    .row {
      justify-content: center;
      .services-container {
        width: 90%;
        @include media("<1000px") {
          width: 100%;
        }
        .heading {
          // margin-top: 50px;
          .phrase {
            text-align: center;
            margin-bottom: 66.4px;
            h1 {
              font-size: 3rem;
              font-weight: 700;
              @include media("<1000px") {
                font-size: 2.2rem;
              }
            }
          }
        }
        .services-wrapper {
          // margin-top: 50px;
          display: flex;
          justify-content: space-between;
          @include media("<1000px") {
            flex-wrap: wrap;
            justify-content: center;
          }
          .service {
            width: 350px;
            margin: 0px 20px;
            background-color: $white;
            text-align: center;
            @include media("<desktop") {
              width: 300px;
            }
            @include media("<1000px") {
              margin-bottom: 40px;
              width: 300px;
            }

            .service-label {
              font-weight: 600;
              font-size: 1.6rem;
              margin-bottom: 1.2rem;
              h3 {
                margin-bottom: 20px;
              }
            }
            .service-info {
              padding: 0px;
              padding-bottom: 30px;
              .service-info-item {
                list-style: none;
                font-weight: 600;
                font-size: 1.2rem;
                margin-bottom: 0.4rem;
              }
            }
          }
        }
      }
    }
  }
}
