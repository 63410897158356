.about {
  height: 75vh; // fallback options
  height: calc(var(--vh, 1vh) * 75);
  width: 100%;
  background-color: $light-background;
  overflow: hidden;
  @include media("<1000px") {
    height: 100%;
  }
  .container {
    .row {
      .about-content {
        display: flex;
        align-items: center;
        margin: 0 auto;
        // height: 90%;
        // width: 90%;
        padding: 40px;
        padding-top: 50px;
        @include media("<desktop") {
          padding: 0;
          padding-top: 50px;
        }
        @include media("<=1000px") {
          flex-direction: column;
          align-items: center;
          height: 90%;
          width: 100%;
          // margin-top: 50px;
          padding-bottom: 0;
        }
        @include media("<=tablet") {
          // margin-top: 50px;
          // padding: 0;
        }
        @include media("<=428px") {
          // margin-top: 40px;
          padding: 0;
          padding-top: 40px;
        }
        .about-content-img {
          // height: auto;
          height: 100%;
          width: 50%;
          @include media("<=1000px") {
            width: 60%;
          }
          @include media("<=428px") {
            height: 250px;
            width: 300px;
          }
          .about-img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            @include media("<=1000px") {
              height: 375px;
            }
            @include media("<=tablet") {
              height: 300px;
            }
            @include media("<=428px") {
              height: 250px;
              width: 300px;
            }
          }
        }
        .paragraph {
          z-index: 10;
          align-self: center;
          margin: 0px 0px 0px -60px;
          padding: 60px;
          background-color: $white;
          // height: 70%;
          width: 45%;
          @include media("<=desktop") {
            // height: 75%;
            // height: 100%;
            margin: 0px 0px 0px -40px;
            // margin: 0px 0px 0px 0px;
            padding: 40px;
            width: 55%;
          }
          @include media("<=1000px") {
            background-color: inherit;
            width: 90%;
            margin: 0;
          }
          @include media("<428px") {
            margin-top: 0px;
            width: 95%;
          }
          .paragraph-content {
            margin: 0 auto;
            h2 {
              margin-top: 0;
            }
            p {
              margin: 0;
              font-weight: 600;
              font-size: 1.2rem;
              letter-spacing: 0.05rem;
              @include media(">=1560px") {
                padding-top: 20px;
                line-height: 1.3rem;
              }
            }
          }
        }
      }
    }
  }
}
