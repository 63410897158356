.main {
  height: 100vh; // fallback options
  height: calc(var(--vh, 1vh) * 100);
  background-color: $white;

  .banner-background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../assets/concrete.jpg");
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .container {
    .row {
      flex-direction: column;
      justify-content: space-between;
      h2 {
        padding-top: 150px;
        font-size: 3rem;
        line-height: 4.2rem;
        font-weight: 700;
        z-index: 10;
        color: $white;
        mix-blend-mode: normal;
        @include media("<=tablet") {
          font-size: 2.4rem;
          line-height: 3rem;
          padding-top: 150px;
        }
        @include media("<=phone") {
          margin-top: 72px;
          font-size: 1.7rem;
          line-height: 2.2rem;
          padding-top: 75px;
        }
        .line {
          margin-bottom: 8px;
          height: 56px;
          position: relative;
          overflow-wrap: break-word;
          @include media("<=tablet") {
            height: 48px;
          }
          @include media("<=phone") {
            height: 32px;
          }
          span {
            position: absolute;
          }
        }
      }
      .btn-row {
        width: 256px;
        position: relative;
        z-index: 2;

        a {
          font-size: 1.6rem;
          color: $black;
          text-decoration: none;
          display: flex;
          align-items: center;
          font-weight: 600;
          @include media("<=tablet") {
            font-size: 1.3rem;
          }
          @include media("<=phone") {
            font-size: 1.1rem;
          }
          svg {
            margin-left: 16px;
            border: 2px solid $black;
            padding: 12px;
            height: 24px;
            width: 24px;
            border-radius: 100px;
            transition: 0.4s ease-in-out;

            @include media("<=tablet") {
              padding: 6px;
              width: 20px;
              height: 20px;
            }
            @include media("<=phone") {
              padding: 4px;
              width: 16px;
              height: 16px;
            }
          }
          &:hover {
            svg {
              background: $black;
              color: $white;
            }
          }
        }
      }
      .btn-row-bottom {
        text-align: center;
        ul {
          padding-left: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          list-style: none;
          li {
            font-weight: 600;
            font-size: 1.8rem;
            margin-bottom: 1.2rem;
            display: inline;
            @include media("<=tablet") {
              font-weight: 800;
              font-size: 1.3rem;
              margin-bottom: 1rem;
            }
            @include media("<=phone") {
              font-size: 1.2rem;
              margin-bottom: 0.875rem;
            }
            a {
              color: $white;
              text-decoration: none;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                width: 0;
                height: 2px;
                display: block;
                margin-top: 5px;
                right: 0;
                background: $white;
                transition: 0.4s ease;
              }
              &:hover {
                &:after {
                  width: 100%;
                  left: 0;
                  background: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
