@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700&display=swap");
@import "./varibles.scss";
@import "./breakpoints.scss";
@import "./components/header.scss";
@import "./components/banner.scss";
@import "./components/navigation.scss";
@import "./components/about.scss";
@import "./components/services.scss";
@import "./components/contactus.scss";
body,
html {
  visibility: hidden;
  user-select: none;
  overscroll-behavior: none;
  margin: 0;
  font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;
  @include media("<=1560px") {
    width: 1280px;
    min-width: 1280px;
  }
  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }
  @include media("<=1080px") {
    width: 100%;
    min-width: 100%;
  }
  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;
    @include media("<=phone") {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    height: 100%;
  }
}

//intro animation

.intro-overlay {
  .top-row {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    width: 100%;
    z-index: 8;
    .top {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: $black;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .bottom-row {
    height: 50vh;
    height: calc(var(--vh, 1vh) * 50);
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 8;
    .bottom {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: $black;
      top: 100%;
      right: 0;
    }
  }
}

.App {
  z-index: 2;
  transform: translateY(0);
  position: relative;
}
.page {
  height: 100vh;
  width: 100%;
  background: $white;
  .row {
    display: flex;
    align-items: center;
  }
}
