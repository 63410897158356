.contact {
  // height: 75vh; // fallback options
  // height: calc(var(--vh, 1vh) * 75);
  width: 100%;
  background-color: $light-background;
  overflow-x: hidden;
  overflow-y: hidden;
  // @include media("<1000px") {
  //   height: 100%;
  // }

  //needs mobile view done
  .container {
    .row {
      justify-content: center;
      align-items: center;
      .contact-us-container {
        display: flex;
        width: 90%;
        align-items: center;
        padding-bottom: 60px;
        // padding-top: 60px;
        @media screen and( max-height: 850px) {
          padding-top: 60px;
        }
        @include media("<1000px") {
          padding-top: 20px;
        }
        @include media("<900px") {
          flex-direction: column;
          width: 100%;
          max-width: 990px;
        }
        .contact-content-left {
          // padding: 0px 40px;
          height: 100%;
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          @include media("<900px") {
            width: 100%;
          }
          .contact-text {
            font-size: 3rem;
            @include media("<900px") {
              font-size: 2.2rem;
              text-align: center;
            }
          }
          p {
            font-weight: 600;
            font-size: 1.3rem;
            letter-spacing: 0.05rem;
            @include media("<900px") {
              padding: 0px 52px 0px 70px;
              text-align: center;
            }
            @include media("<tablet") {
              padding: 0px 15px;
            }
          }
        }
        .contact-content-right {
          padding: 0px 40px;
          display: flex;
          align-items: center;
          height: 100%;
          width: 50%;
          @include media("<900px") {
            width: 70%;
          }

          .contact-content {
            width: 100%;
            .contact-item {
              font-weight: 600;
              font-size: 1.3rem;
              letter-spacing: 0.05rem;
              @include media("<phone") {
                word-break: break-all;
              }
            }
            .contact-form {
              .input-container {
                padding-top: 15px;
                .contact-input {
                  padding: 10px;
                  width: 100%;
                  border: 0;
                  outline: 0;
                  background: transparent;
                  border-bottom: 1px solid black;
                }
                .input-button {
                  padding: 10px;
                  border: 1px solid black;
                  outline: 0;
                  background: transparent;
                  transition: 0.4s ease-in-out;
                  cursor: pointer;
                }
                &:hover {
                  .input-button {
                    background-color: $black;
                    color: $white;
                  }
                }
                .errors {
                  color: red;
                  padding-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
